export default (() => {
  switch (true) {
    case (typeof document.currentScript !== 'undefined') && !!document.currentScript:
      return document.currentScript;
    default:
      let scripts;
      try {
        throw new Error();
      }
      catch (e) {
        const srcs = e.stack.match(/https?:\/\/([^\/]+)([a-z\.\/\-]+\.js)/g);
        const src = srcs[srcs.length - 1];
        const absoluteUrl = src;
        scripts = document.scripts;
        let i = -1;
        const l = scripts.length;
        while (++i < l) {
          if (scripts[i][0] === '/' && scripts[i].src === absoluteUrl || scripts[i].src === src) {
            return scripts[i];
          }
        }
      }
      scripts = document.getElementsByTagName('script');
      return scripts[scripts.length - 1];
  }
})();