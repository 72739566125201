import requireConfig from "./requireConfig";
import { requirejs, define } from "requirejs/require";
import currentScript from "./currentScript";

const defineLocal = (() => {
  if (window && window["define.abcp.carousel"]) {
    return window["define.abcp.carousel"];
  }
  (window || {})["define.abcp.carousel"] = define;
  return define;
})();
const requirejsLocal = (() => {
  if (window && window["requirejs.abcp.carousel"]) {
    return window["requirejs.abcp.carousel"];
  }
  (window || {})["requirejs.abcp.carousel"] = requirejs;
  return requirejs;
})();
const req = requirejsLocal.config(requireConfig);
req(["abcp.carousel"], (widget) => {
  return typeof widget === "function" && widget(currentScript);
});
